export const toBoolean = (value: string | number | boolean): boolean => [true, 'true', 'True', 'TRUE', '1', 1].includes(value);
export const randomInt = (start:number, end:number) => Math.floor(Math.random() * (end - start)) + start;
export const randomColor = () => "#"+((1<<24)*Math.random()|0).toString(16);
export const comma = (str:any) => String(str).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
export const uncomma = (str:any) => String(str).replace(/[^0-9]/g,'');
export const randomString = (len:number, charSet:string = '') => {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let str = '';
    for (let i = 0; i < len; i++) {
        const pos = Math.floor(Math.random() * charSet.length);
        str += charSet.substring(pos, pos+1);
    }
    return str;
}

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
};

export const isOs = {
    android: () => navigator.userAgent.match(/Android/i),
    blackberry: () => navigator.userAgent.match(/BlackBerry/i),
    ios: () => navigator.userAgent.match(/iPhone|iPod|iPad/i),
    opera: () => navigator.userAgent.match(/Opera Mini/i),
    windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
    any: () => (isOs.android() || isOs.blackberry() || isOs.ios() || isOs.opera() || isOs.windows())
};

export const browserName = () => {
    const browsers = [
        'Chrome', 'Opera',
        'WebTV', 'Whale',
        'Beonex', 'Chimera',
        'NetPositive', 'Phoenix',
        'Firefox', 'Safari',
        'SkipStone', 'Netscape',
        'Mozilla',
    ];
      
    const userAgent = navigator.userAgent.toLowerCase();
    if(userAgent.includes('edg')) return 'Edge';
    else if(userAgent.includes('trident') || userAgent.includes('msie')) return "Internet Explorer";
    else {
        return browsers.find(browser => userAgent.includes(browser.toLowerCase())) || 'Other';
    }
}

export const isMobile = () => navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
export const trim = (str:any) => String(str).replace(/(^\s*)|(\s*$)/gi, "");

export const isValidateIP = (str:string) => {
    const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return pattern.test(str)
}

export const toSnake = (str:string) => str.replace(/([a-zA-Z])(?=[A-Z])/g,'$1_').toLowerCase()
export const toCamel = (str:string) => str.replace(/(_\w)/g, k => k[1].toUpperCase())

export const GetMarketOrder = (bets:any) => {
    // 1X2 순서, Under/Over 순서
    const sort_order = [ '1', 'X', '2', 'Under', 'Over' ]
    return bets.sort((a:any, b:any) => {
        // line 정렬
        if(a['Line'] > b['Line']) return 1;
        else if(a['Line'] < b['Line']) return -1;

      
        let aName = a['Name']
        let bName = b['Name']

        // 1X2
        if(sort_order.indexOf(bName) !== -1) {
            return sort_order.indexOf(aName) - sort_order.indexOf(bName)
        } 

        if(aName > bName) return 1;
        else if(aName < bName) return -1;
        

        return 0;
    })
}

export const isHalfWinBets = (a:string | number) => {
    const v = ((Number(a) * 10) % 5)
    return (v == 0) ? false : true;
}


